import './App.css';

import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import BondingForm from './components/Bonding';
import SublimationForm from './components/Submilmation';
import LaserForm from './components/Laser';
import { useState } from 'react';

function App() {
  const [config, setConfig] = useState({
    overheadCost: 0.43,
    costMargin: 45
  });

  const [selectedComp, setSelectedComp] = useState("0");

  return (
    <div className="App">
      <Router>
        <div className="header">
          <ul>
            <li><Link to="/bonding" onClick={ () => {setSelectedComp("0")} } className={(selectedComp === "0") ? "active" : undefined} >Bonding</Link></li>
            <li><Link to="/sublimation" onClick={ () => {setSelectedComp("1")} } className={(selectedComp === "1") ? "active" : undefined}>Sublimation</Link></li>
            <li><Link to="/laser" onClick={ () => {setSelectedComp("2")} }  className={(selectedComp === "2") ? "active" : undefined}>Laser</Link></li>
          </ul>
        </div>
        <hr />
        <div className="app-component">
          <Switch>
            <Route exact path="/" >
              <Redirect to="/bonding" />
            </Route>
            <Route path="/bonding">
              <BondingForm margin={config.costMargin} overHead={Number(config.overheadCost)} />
            </Route>
            <Route path="/sublimation">
              <SublimationForm margin={config.costMargin} overHead={Number(config.overheadCost)} />
            </Route>
            <Route path="/laser">
              <LaserForm margin={config.costMargin} overHead={Number(config.overheadCost)} />
            </Route>
          </Switch>
        </div> 
        <hr />
        <div className="footer">
          <label>
            Overhead Cost : 
            <input type="text" value={config.overheadCost} 
              onChange={
                  (e) => {
                    setConfig({
                      overheadCost: e.target.value,
                      costMargin: config.costMargin
                    },)
                  }
              }
            />
          </label>
          <label>
            Profit Margin : 
            <input type="text" value={config.costMargin} 
              onChange={
                  (e) => {
                    setConfig({
                      costMargin: Number(e.target.value),
                      overheadCost: config.overheadCost
                    },)
                  }
              }
            />
          </label>
        </div>
      </Router>
      <div className="footnote">
        <p>Developed by <a rel="noreferrer"  href="https://apptech.global" target="_blank" >AppTech Innovations</a>.</p>
      </div>
    </div>
  );
}

export default App;
