import { useState } from "react";

const SublimationForm = ({margin, overHead}) => {

    const round = (price) => Math.round((price + Number.EPSILON) * 100) / 100;
    const [timeSpent, setTimeSpent] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [costPrice, setCostPrice] = useState(0);
    const [marginAmount, setMarginAmount] = useState(0);


    const [materialInfo, setMaterialInfo] = useState({
        pPaper: "",
        sPaper: "",
        inkC: "",
        inkM: "",
        inkY: "",
        inkK: "",
        pPaperQ: 0,
        sPaperQ: 0,
        inkCQ: 0,
        inkMQ: 0,
        inkYQ: 0,
        inkKQ: 0
    });

    return (
        <div>
            <h1>Sublimation Costing</h1>
            <form>
                <label>
                    Sublimation Paper Cost per meter (Rs): 
                    <input type="text" value={materialInfo.pPaper} onChange={(e) => setMaterialInfo({...materialInfo, pPaper:e.target.value})} />
                </label>
                <label>
                    Sublimation Paper Qty (m): 
                    <input type="number" value={materialInfo.pPaperQ} onChange={(e) => setMaterialInfo({...materialInfo, pPaperQ:e.target.value})} />
                </label>
                <br />
                <label>
                    Plotter Paper Cost per meter (Rs): 
                    <input type="text" value={materialInfo.sPaper} onChange={(e) => setMaterialInfo({...materialInfo, sPaper:e.target.value})} />
                </label>
                <label>
                    Plotter Paper Paper Qty (m): 
                    <input type="number" value={materialInfo.sPaperQ} onChange={(e) => setMaterialInfo({...materialInfo, sPaperQ:e.target.value})} />
                </label>
                <br />
                <label>
                    Ink (C) Cost for milliliter (Rs): 
                    <input type="text" value={materialInfo.inkC} onChange={(e) => setMaterialInfo({...materialInfo, inkC:e.target.value})} />
                </label>
                <label>
                    Ink (C) Qty (ml): 
                    <input type="number" value={materialInfo.inkCQ} onChange={(e) => setMaterialInfo({...materialInfo, inkCQ:e.target.value})} />
                </label>
                <br />
                <label>
                    Ink (M) Cost for milliliter (Rs): 
                    <input type="text" value={materialInfo.inkM} onChange={(e) => setMaterialInfo({...materialInfo, inkM:e.target.value})} />
                </label>
                <label>
                    Ink (M) Qty (ml): 
                    <input type="number" value={materialInfo.inkMQ} onChange={(e) => setMaterialInfo({...materialInfo, inkMQ:e.target.value})} />
                </label>
                <br />
                <label>
                    Ink (Y) Cost for milliliter (Rs): 
                    <input type="text" value={materialInfo.inkY} onChange={(e) => setMaterialInfo({...materialInfo, inkY:e.target.value})} />
                </label>
                <label>
                    Ink (Y) Qty (ml):  
                    <input type="number" value={materialInfo.inkYQ} onChange={(e) => setMaterialInfo({...materialInfo, inkYQ:e.target.value})} />
                </label>
                <br />
                <label>
                    Ink (K) Cost for milliliter (Rs): 
                    <input type="text" value={materialInfo.inkK} onChange={(e) => setMaterialInfo({...materialInfo, inkK:e.target.value})} />
                </label>
                <label>
                    Ink (K) Qty (ml): 
                    <input type="number" value={materialInfo.inkKQ} onChange={(e) => setMaterialInfo({...materialInfo, inkKQ:e.target.value})} />
                </label>
                <br />
                <label>
                    Overhead Time (sec): 
                    <input type="text" value={timeSpent} onChange={(e) => setTimeSpent(Number(e.target.value))} />
                </label>
                <br />
                <button onClick={
                    (e) =>{
                        e.preventDefault();

                        let tempCP =    (Number(materialInfo.sPaper) * Number(materialInfo.sPaperQ)) + 
                                        (Number(materialInfo.pPaper) * Number(materialInfo.pPaperQ)) + 
                                        (Number(materialInfo.inkC) * Number(materialInfo.inkCQ)) + 
                                        (Number(materialInfo.inkM) * Number(materialInfo.inkMQ)) + 
                                        (Number(materialInfo.inkY) * Number(materialInfo.inkYQ)) + 
                                        (Number(materialInfo.inkK) * Number(materialInfo.inkKQ)) + 
                                        round(overHead * timeSpent) ;
                        let tempMA = round((tempCP * margin/100));
                        let tempTP = round(tempCP + tempMA);

                        setCostPrice(tempCP);
                        setMarginAmount(tempMA);
                        setTotalPrice(tempTP);
                    }
                }>Calculate Price</button>
                <button className="reset" onClick={(e) => {
                    e.preventDefault();
                    setTimeSpent("");
                    setCostPrice(0);
                    setMarginAmount(0);
                    setTotalPrice(0);
                }} >Reset</button>
            </form>
            <div className="breakdown" >
                <h2>Price : {totalPrice}</h2>
                <p>Total Cost: {costPrice}</p>
                <p>Profit Margin in (Rs): {marginAmount}</p>
            </div>
        </div>
    );
}

export default SublimationForm;