import { useState } from "react";

const LaserForm = ({margin, overHead}) => {

    const round = (price) => Math.round((price + Number.EPSILON) * 100) / 100;
    
    const [timeSpent, setTimeSpent] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [costPrice, setCostPrice] = useState(0);
    const [marginAmount, setMarginAmount] = useState(0);

    return (
        <div>
            <h1>Laser Costing</h1>
            <form>
                <label>
                    Time Spent (seconds) <input type="text" value={timeSpent} onChange={(e) => setTimeSpent(Number(e.target.value))} />
                </label>
                <button onClick={
                    (e) =>{
                        e.preventDefault();

                        let tempCP = round(overHead * timeSpent);
                        let tempMA = round((tempCP * margin/100));
                        let tempTP = round(tempCP + tempMA);

                        setCostPrice(tempCP);
                        setMarginAmount(tempMA);
                        setTotalPrice(tempTP);
                    }
                }>Calculate Price</button>
                <button className="reset" onClick={(e) => {
                    e.preventDefault();
                    setTimeSpent("");
                    setCostPrice(0);
                    setMarginAmount(0);
                    setTotalPrice(0);
                }} >Reset</button>
            </form>
            <div className="breakdown" >
                <h2>Price : {totalPrice}</h2>
                <p>Total Cost: {costPrice}</p>
                <p>Profit Margin in (Rs): {marginAmount}</p>
            </div>
        </div>
    );
}

export default LaserForm;