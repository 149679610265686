import { useState } from "react";

const BondingForm = ({margin, overHead}) => {

    const round = (price) => Math.round((price + Number.EPSILON) * 100) / 100;
    const [materialInfo, setMaterialInfo] = useState({
        mWidth: "",
        mLength: "",
        sWidth: "",
        sLength: "",
        mPrice: "",
        sCost: 0
    });

    const [timeSpent, setTimeSpent] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [costPrice, setCostPrice] = useState(0);
    const [marginAmount, setMarginAmount] = useState(0);

    return (
        <div>
            <h1>Bonding Costing</h1>
            <form>
                <label>Material Costing</label>
                <label>
                    Material Width (cm): <input type="text" value={materialInfo.mWidth} onChange={
                        (e) => {
                            setMaterialInfo({...materialInfo, mWidth: (e.target.value)})
                        }
                    } />
                </label>
                <br />
                <label>
                    Material Length (cm): <input type="text" value={materialInfo.mLength} onChange={
                        (e) => {
                            setMaterialInfo({...materialInfo, mLength: (e.target.value)})
                        }
                    } />
                </label>
                <br />
                <label>
                    Sticker Width (cm): <input type="text" value={materialInfo.sWidth} onChange={
                        (e) => {
                            setMaterialInfo({...materialInfo, sWidth: (e.target.value)})
                        }
                    } />
                </label>
                <br />
                <label>
                    Sticker length (cm): <input type="text" value={materialInfo.sLength} onChange={
                        (e) => {
                            setMaterialInfo({...materialInfo, sLength: (e.target.value)})
                        }
                    } />
                </label>
                <br />
                <label>
                    Material Price per square meters (Rs): <input type="text" value={materialInfo.mPrice} onChange={
                        (e) => {
                            setMaterialInfo({...materialInfo, mPrice: (e.target.value)})
                        }
                    } />
                </label>
                <br />
                <div>
                    <h2>Cost Per Sticker : {materialInfo.sCost}</h2>
                </div>
                <br />
                <button onClick={
                    (e) => {
                        e.preventDefault();
                        let mSize = Number(materialInfo.mWidth)*Number(materialInfo.mLength);
                        let sSize = Number(materialInfo.sWidth)*Number(materialInfo.sLength);

                        let noOfBonding = mSize/sSize;

                        let priceofBonding = round(Number(materialInfo.mPrice)/noOfBonding);
                        
                        setMaterialInfo({...materialInfo, sCost: priceofBonding});
                    }
                }>Calculate Material Cost</button>
                <button className="reset" onClick={
                    (e) => {
                        e.preventDefault();
                        setMaterialInfo({
                            mWidth: "",
                            mLength: "",
                            sWidth: "",
                            sLength: "",
                            mPrice: 0,
                            sCost: 0
                        });
                    }
                }>Reset</button>
            </form>
            <br/>
            <form>
                <br />
                <label>
                    Overhead Time (sec): 
                    <input type="text" value={timeSpent} onChange={(e) => setTimeSpent(Number(e.target.value))} />
                </label>
                <br />
                <button onClick={
                    (e) =>{
                        e.preventDefault();

                        let tempCP = round(overHead * timeSpent) + round(materialInfo.sCost);
                        let tempMA = round((tempCP * margin/100));
                        let tempTP = round(tempCP + tempMA);

                        setCostPrice(tempCP);
                        setMarginAmount(tempMA);
                        setTotalPrice(tempTP);

                    }
                }>Calculate Price</button>
                <button className="reset" onClick={(e) => {
                    e.preventDefault();
                    setTimeSpent("");
                    setCostPrice(0);
                    setMarginAmount(0);
                    setTotalPrice(0);
                }} >Reset</button>
            </form>
            <div className="breakdown" >
                <h2>Price Per Sticker : {totalPrice}</h2>
                <p>Total Cost: {costPrice}</p>
                <p>Profit Margin in (Rs): {marginAmount}</p>
            </div>
        </div>
    );
}

export default BondingForm;